<template>
  <div>
    <div>
      <template>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("Sales Plan") }}</v-toolbar-title>
        </v-toolbar>
      </template>
    </div>
    <div class="mt-4">
      <div class="d-flex data-year-inputs">
        <v-hover v-slot="{ hover }">
          <v-btn
            v-on:click="decreaseYear()"
            class="mp-btn mr-1"
            :color="hover ? 'primary' : 'grey'"
            small
            outlined
            fab
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-hover>
        <v-text-field
          v-model.number="salesPlanYear"
          min="4"
          max="4"
          class="number data-year shrink"
          outlined
          dense
        ></v-text-field>

        <v-hover v-slot="{ hover }">
          <v-btn
            v-on:click="increaseYear()"
            class="mp-btn ml-1"
            :color="hover ? 'primary' : 'grey'"
            small
            outlined
            fab
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-hover>
        <v-spacer></v-spacer>

        <v-switch
          v-if="
            $store.state.user.user.role == 0 || $store.state.user.user.role == 1
          "
          v-model="lockTotal"
          :label="$t('Lock Totals')"
          color="primary"
          class="mr-2 mt-1"
        ></v-switch>
        <v-btn
          v-if="
            $store.state.user.user.role == 0 || $store.state.user.user.role == 1
          "
          color="green"
          class="mr-2"
          @click="discardChanges"
          rounded
          :disabled="!dataUpdated"
          >{{ $t("Cancel") }}</v-btn
        >
        <v-btn
          v-if="
            $store.state.user.user.role == 0 || $store.state.user.user.role == 1
          "
          color="primary"
          @click="saveSaleData"
          rounded
          :disabled="!dataUpdated"
          >{{ $t("Save") }}</v-btn
        >
      </div>
      <div>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("Company Level")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="ex-cdata">
              <v-expansion-panels id="sales-plan-months-header-company" accordion flat>
                <v-expansion-panel disabled>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon ex-year">$expand</v-icon>
                    </template>
                    <div class="ex-header ex-year-header" @click.stop>
                      <div class="measure-months-header">
                        <div class="user-m measure-name">
                          <div></div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("January") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("February") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("March") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("April") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("May") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("June") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("July") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("August") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("September") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("October") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("November") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("December") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("Sales Plan Total") }}</div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                class="measureMonths"
                accordion
                v-for="(measure, index) in salesData.measures"
                :key="index"
                flat
              >
                <v-expansion-panel
                  v-if="measure.type == 0"
                  :disabled="!measure.allowsUserDistribution"
                >
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon">$expand</v-icon>
                    </template>
                    <div class="ex-header" @click.stop>
                      <div class="measure-months">
                        <div class="user-m measure-name">
                          <div>{{ measure.name }}</div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['1']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '1', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['2']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '2', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['3']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '3', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['4']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '4', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['5']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '5', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['6']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '6', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['7']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '7', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['8']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '8', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['9']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '9', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['10']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '10', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['11']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '11', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['12']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '12', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m m-total">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['total']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, 'total')"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="inner-panel">
                    <div
                      class="user-months"
                      v-for="(userData, index) in measure.userMonths"
                      :key="index"
                    >
                      <div class="user-m user-m-name">
                        <div>{{ getUser(userData.userId) }}</div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['1']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '1', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['2']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '2', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['3']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '3', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['4']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '4', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['5']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '5', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['6']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '6', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['7']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '7', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['8']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '8', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['9']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '9', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['10']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '10', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['11']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '11', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['12']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '12', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m m-total">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['total']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, 'total', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("Tactical")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="ex-cdata">
              <v-expansion-panels id="sales-plan-months-header-tactical" accordion flat>
                <v-expansion-panel disabled>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon ex-year">$expand</v-icon>
                    </template>
                    <div class="ex-header ex-year-header" @click.stop>
                      <div class="measure-months-header">
                        <div class="user-m measure-name">
                          <div></div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("January") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("February") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("March") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("April") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("May") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("June") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("July") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("August") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("September") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("October") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("November") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("December") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("Sales Plan Total") }}</div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                class="measureMonths"
                accordion
                v-for="(measure, index) in salesData.measures"
                :key="index"
                flat
              >
                <v-expansion-panel
                  v-if="measure.type == 1"
                  :disabled="!measure.allowsUserDistribution"
                >
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon">$expand</v-icon>
                    </template>
                    <div class="ex-header" @click.stop>
                      <div class="measure-months">
                        <div class="user-m measure-name">
                          <div>{{ measure.name }}</div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['1']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '1', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['2']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '2', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['3']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '3', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['4']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '4', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['5']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '5', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['6']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '6', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['7']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '7', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['8']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '8', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['9']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '9', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['10']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '10', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['11']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '11', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['12']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '12', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m m-total">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['total']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, 'total')"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="inner-panel">
                    <div
                      class="user-months"
                      v-for="(userData, index) in measure.userMonths"
                      :key="index"
                    >
                      <div class="user-m user-m-name">
                        <div>{{ getUser(userData.userId) }}</div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['1']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '1', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['2']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '2', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['3']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '3', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['4']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '4', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['5']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '5', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['6']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '6', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['7']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '7', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['8']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '8', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['9']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '9', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['10']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '10', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['11']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '11', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['12']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '12', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m m-total">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['total']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, 'total', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("Activities")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="ex-cdata">
              <v-expansion-panels id="sales-plan-months-header-activities" accordion flat>
                <v-expansion-panel disabled>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon ex-year">$expand</v-icon>
                    </template>
                    <div class="ex-header ex-year-header" @click.stop>
                      <div class="measure-months-header">
                        <div class="user-m measure-name">
                          <div></div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("January") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("February") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("March") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("April") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("May") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("June") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("July") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("August") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("September") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("October") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("November") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("December") }}</div>
                        </div>
                        <div class="user-m">
                          <div class="months-txt">{{ $t("Sales Plan Total") }}</div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                class="measureMonths"
                accordion
                v-for="(measure, index) in salesData.measures"
                :key="index"
                flat
              >
                <v-expansion-panel
                  v-if="measure.type == 2"
                  :disabled="!measure.allowsUserDistribution"
                >
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="ex-icon">$expand</v-icon>
                    </template>
                    <div class="ex-header" @click.stop>
                      <div class="measure-months">
                        <div class="user-m measure-name">
                          <div>{{ measure.name }}</div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['1']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '1', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['2']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '2', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['3']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '3', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['4']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '4', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['5']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '5', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['6']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '6', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['7']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '7', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['8']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '8', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['9']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '9', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['10']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '10', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['11']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '11', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['12']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, '12', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="user-m m-total">
                          <div>
                            <v-text-field
                              v-model.number="measure.months['total']"
                              type="number"
                              min="0"
                              class="shrink"
                              small
                              dense
                              @input="inputChanged(measure, 'total', true)"
                              :readonly="
                                lockTotal || $store.state.user.user.role == 2
                              "
                            ></v-text-field
                            ><span class="pt-c-10">{{
                              measure.showPercentage == true ? "%" : ""
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="inner-panel">
                    <div
                      class="user-months"
                      v-for="(userData, index) in measure.userMonths"
                      :key="index"
                    >
                      <div class="user-m user-m-name">
                        <div>{{ getUser(userData.userId) }}</div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['1']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '1', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['2']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '2', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['3']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '3', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['4']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '4', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['5']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '5', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['6']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '6', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['7']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '7', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['8']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '8', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['9']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '9', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['10']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '10', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['11']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '11', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['12']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, '12', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                      <div class="user-m m-total">
                        <div>
                          <v-text-field
                            :readonly="$store.state.user.user.role == 2"
                            v-model.number="userData.months['total']"
                            type="number"
                            min="0"
                            class="shrink"
                            small
                            dense
                            @input="
                              userInputChanged(measure, userData, 'total', true)
                            "
                            :key="componentKey"
                          ></v-text-field
                          ><span class="pt-c-10">{{
                            measure.showPercentage == true ? "%" : ""
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      salesPlanYear: "",
      expanded: [],
      salesData: [],
      users: [],
      lockTotal: false,
      dataUpdated: false,
      resetLoad: false,
      componentKey: 0,
      userInputChangedTimeout: null,
      isCalculating: false,
    };
  },

  watch: {
    salesPlanYear: {
      handler() {
        if (this.salesPlanYear.toString().length == 4) {
          this.$router.replace({ query: { year: this.salesPlanYear } });
          this.fetchItems();
        }
      },
    },
    salesData: {
      handler() {
        if (this.resetLoad == true) {
          this.dataUpdated = true;
        } else {
          this.resetLoad = true;
          this.dataUpdated = false;
        }
      },
      deep: true,
    },
    lockTotal: {
      handler() {},
    },
  },
  mounted() {},
  created: function () {
    if (this.$route.query.year) {
      this.salesPlanYear = this.$route.query.year;
    } else {
      this.salesPlanYear = new Date().getFullYear();
      this.$router.replace({ query: { year: this.salesPlanYear } });
    }

    this.fetchUsers();
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    decreaseYear() {
      this.salesPlanYear =
        parseInt(this.salesPlanYear) <= 0
          ? 0
          : parseInt(this.salesPlanYear) - 1;
      this.$router.replace({ query: { year: this.salesPlanYear } });
    },
    increaseYear() {
      this.salesPlanYear = parseInt(this.salesPlanYear) + 1;
      this.$router.replace({ query: { year: this.salesPlanYear } });
    },
    getUser(userid) {
      let filterUser = this.users.filter(function (user) {
        return user.id == userid;
      });
      
      if(filterUser[0] != undefined)
      {
        var lastName = ""
        if(filterUser[0].lastName != null && filterUser[0].lastName != undefined){
          if(filterUser[0].lastName.length > 0){
            lastName = filterUser[0].lastName.substr(0,1) + "."
          }
        }
        return filterUser[0].firstName + " " + lastName
      }

      return ""
    },
    getMonthTotal(months, showPercentage) {
      let total = 0;
      for (const key in months) {
        if (Object.hasOwnProperty.call(months, key) && key != "total") {
          const value = months[key];
          total += value;
        }
      }
      if (showPercentage) {
        months["total"] = Math.round((total / 12) * 100) / 100;
      } else {
        months["total"] = Math.round(total * 100) / 100;
      }
    },
    getUserMonthTotal(months, showPercentage) {
      let total = 0;
      for (const key in months) {
        if (Object.hasOwnProperty.call(months, key) && key != "total") {
          const value = months[key];
          total += value;
        }
      }
      if (showPercentage) {
        months["total"] = Math.round((total / 12) * 100) / 100;
      } else {
        months["total"] = Math.round(total * 100) / 100;
      }
    },
    getAllTotals(salesData) {
      salesData.measures.forEach((measure) => {
        this.getMonthTotal(measure.months, measure.showPercentage || measure.isAverageCalculation);
        measure.userMonths.forEach((userData) => {
          this.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
        });
      });
    },
    fetchUsers() {
      let uri = this.$urlPrefix + "/User/CountAll?includeDeleted=true";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/User/GetList?start=0&count=" +
          response.data +
          "&includeDeleted=true";
        this.axios.get(uri2).then((response) => {
          this.users = response.data;
        });
      });
    },
    fetchItems() {
      if (this.salesPlanYear.toString().length == 4) {
        let uri = this.$urlPrefix + "/SalesPlan/Get?year=" + this.salesPlanYear;
        this.axios.get(uri).then((response) => {
          this.resetLoad = false;
          this.salesData = response.data;
          this.salesData.measures = this.salesData.measures.sort((a, b) =>
            a.sort > b.sort ? 1 : -1
          );
          this.getAllTotals(this.salesData);
        });
      }
    },
    putItems() {
      let uri = this.$urlPrefix + "/SalesPlan/Put";
      this.axios.put(uri, this.salesData).then((response) => {
        if (response.status == 204 || response.status == 200) {
          this.resetLoad = false;
          this.salesData = response.data;
          this.getAllTotals(this.salesData);
          this.$store.dispatch("setSnack", {
            icon: "mdi-thumb-up",
            color: "green",
            msg: this.$t("Sales Data Saved"),
          });
        } else {
          this.$store.dispatch("setSnack", {
            icon: "mdi-alert",
            color: "red",
            msg: this.$t("Failed to save Sales Plan Data"),
          });
        }
      });
    },
    saveSaleData() {
      this.dataUpdated = false;
      this.putItems();
    },
    discardChanges() {
      this.dataUpdated = false;
      this.fetchItems(true);
    },
    inputChanged(measure, month, calculateTotals) {
      var scope = this;

      if (calculateTotals) {
        clearTimeout(this.userInputChangedTimeout);
      }
      this.userInputChangedTimeout = setTimeout(function () {
        scope.calculateMeasure(measure, month, calculateTotals)
      }, 600);
    },
    calculateMeasure(measure, month, calculateTotals){
      var scope = this;

      if (month == "total") {
        scope.redistributeTotal(measure);
      } else {
        scope.calculateMeasureMonth(measure, month);
        measure.userMonths.forEach((userData) => {
          scope.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
        });
        if (calculateTotals) {
          scope.getMonthTotal(measure.months, measure.showPercentage || measure.isAverageCalculation);
        }
      }
    },
    calculateMeasureMonth(measure, month) {
      if (!this.isCalculating) {
        this.isCalculating = true;

        if (!this.lockTotal) {
          let measureMonthValue = measure.months[month];

          let ValueMonth = measure.userMonths.filter(function (usermonth) {
            return usermonth.months[month] > 0;
          });

          let totalUsers = measure.userMonths.length;
          if (ValueMonth.length > 0 && !measure.showPercentage && !measure.isAverageCalculation) {
            if (totalUsers == 1) {
              measure.userMonths[0].months[month] =
                measure.userMonths[0].months[month] +
                (measureMonthValue - measure.userMonths[0].months[month]);
            }

            if (totalUsers > 1) {
              let countUserTotal = 0;
              let totalUserMonths = 0;
              measure.userMonths.forEach((userMData) => {
                totalUserMonths += userMData.months[month];
              });
              measure.userMonths.forEach((userMData) => {
                userMData.months[month] = Math.round(
                  (userMData.months[month] / totalUserMonths) *
                    measureMonthValue
                );
                countUserTotal += userMData.months[month];
              });

              if (measureMonthValue > countUserTotal && !measure.showPercentage && !measure.isAverageCalculation) {
                let remainValue = measureMonthValue - countUserTotal;
                const maxValue = measure.userMonths.reduce(function (
                  prev,
                  current
                ) {
                  return prev.months[month] > current.months[month] 
                    ? prev
                    : current;
                }); //returns object
                maxValue.months[month] =
                  Math.round((maxValue.months[month] + remainValue) * 100) / 100;
              }

              if (measureMonthValue < countUserTotal && !measure.showPercentage && !measure.isAverageCalculation) {
                let remainValue = countUserTotal - measureMonthValue;

                const maxValue = measure.userMonths.reduce(function (
                  prev,
                  current
                ) {
                  return prev.months[month] > current.months[month] 
                    ? prev
                    : current;
                }); //returns object
                maxValue.months[month] =
                  Math.round((maxValue.months[month] - remainValue) * 100) / 100;
              }
            }
          } else {
            let distValue = measure.showPercentage || measure.isAverageCalculation ? measureMonthValue : measureMonthValue / totalUsers;
            let countUserTotal = 0;
            measure.userMonths.forEach((userMData) => {
              userMData.months[month] = Math.round(distValue);
              countUserTotal += Math.round(distValue);
            });
            if (measureMonthValue > countUserTotal && !measure.showPercentage && !measure.isAverageCalculation) {
              let remainValue = measureMonthValue - countUserTotal;

              const maxValue = measure.userMonths.reduce(function (
                prev,
                current
              ) {
                return prev.months[month] > current.months[month]
                  ? prev
                  : current;
              }); //returns object
              maxValue.months[month] =
                Math.round((maxValue.months[month] + remainValue) * 100) / 100;
            }

            if (measureMonthValue < countUserTotal && !measure.showPercentage && !measure.isAverageCalculation) {
              let remainValue = countUserTotal - measureMonthValue;

              const maxValue = measure.userMonths.reduce(function (
                prev,
                current
              ) {
                return prev.months[month] > current.months[month]
                  ? prev
                  : current;
              }); //returns object
              maxValue.months[month] =
                Math.round((maxValue.months[month] - remainValue) * 100) / 100;
            }
          }
        }
      }

      this.isCalculating = false;
    },
    userInputChanged(measure, userData, month, calculateTotals) {
      var scope = this;
      if (calculateTotals) {
        clearTimeout(this.userInputChangedTimeout);
      }
      this.userInputChangedTimeout = setTimeout(function () {
        if (month == "total") {
          scope.redistributeUserTotal(measure, userData);
        } else {
          scope.calculateUserMonth(measure, userData, month);
          scope.getMonthTotal(measure.months, measure.showPercentage || measure.isAverageCalculation);

          if (calculateTotals) {
            scope.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
            measure.userMonths.forEach((userData) => {
              scope.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
            });
          }
        }
      }, 600);
    },
    calculateUserMonth(measure, userData, month) {
      if (!this.lockTotal) {
        let measureTotal = 0;
        measure.userMonths.forEach(function (userMdata) {
          measureTotal += userMdata.months[month];
        });
        measure.months[month] = measure.showPercentage || measure.isAverageCalculation ? 
          Math.round((measureTotal / measure.userMonths.length) * 100)  / 100 : 
          measureTotal;
      } else {
        if (userData.months[month] > measure.months[month] && !measure.showPercentage && !measure.isAverageCalculation) {
          measure.userMonths.forEach(function (userMdata) {
            userMdata.months[month] = 0;
          });
          userData.months[month] = measure.months[month];
        }else if(measure.showPercentage){
          let totalUsers = 0;
          measure.userMonths.forEach(function (userMdata) {
              totalUsers += userMdata.months[month];
          });

          var diff = ((totalUsers - (measure.userMonths.length * measure.months[month])));
          const nextValue = measure.userMonths.reduce(function (
              prev,
              current
            ) {
              return prev.months[month] > current.months[month] && prev.userId != userData.userId
                ? prev
                : current;
            }); 
            nextValue.months[month] = Math.round((nextValue.months[month] - diff) * 100) / 100; 
        } else {
          let totalOtherUser = 0;
          measure.userMonths.forEach(function (userMdata) {
            if (userMdata.userId != userData.userId) {
              totalOtherUser += userMdata.months[month];
            }
          });

          let otherTotalValue = measure.months[month] - userData.months[month];

          let totalDistributed = 0;
          measure.userMonths.forEach(function (userMdata) {
            if (userMdata.userId != userData.userId) {
              userMdata.months[month] = Math.round(
                (userMdata.months[month] / totalOtherUser) * otherTotalValue
              );
            }
            totalDistributed += userMdata.months[month];
          });
          if (totalDistributed > measure.months[month]) {
            let remainValue = totalDistributed - measure.months[month];

            const maxValue = measure.userMonths.reduce(function (
              prev,
              current
            ) {
              return prev.months[month] > current.months[month] && prev.userId != userData.userId
                ? prev
                : current;
            }); //returns object
            maxValue.months[month] =
              Math.round((maxValue.months[month] - remainValue) * 100) / 100;
          }

          if (totalDistributed < measure.months[month]) {
            let remainValue = measure.months[month] - totalDistributed;

            const maxValue = measure.userMonths.reduce(function (
              prev,
              current
            ) {
              return prev.months[month] > current.months[month] && prev.userId != userData.userId
                ? prev
                : current;
            }); //returns object
            maxValue.months[month] =
              Math.round((maxValue.months[month] + remainValue) * 100) / 100;
          }

          this.componentKey += 1;
        }
      }
    },
    redistributeTotal(measure) {
      if (measure.showPercentage || measure.isAverageCalculation) {
        for (const key in measure.months) {
          if (key != "total") {
            measure.months[key] = measure.months["total"];
            this.calculateMeasureMonth(measure, key);
          }
        }
        measure.userMonths.forEach((userData) => {
          this.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
        });
      } else {
        for (const key in measure.months) {
          if (key != "total") {
            measure.months[key] = Math.round((measure.months["total"] / 12) * 100) / 100;
            this.calculateMeasureMonth(measure, key)
          }
        }
        measure.userMonths.forEach((userData) => {
          this.getUserMonthTotal(userData.months, measure.showPercentage || measure.isAverageCalculation);
        });
      }
    },
    redistributeUserTotal(measure, userData) {
      if (measure.showPercentage || measure.isAverageCalculation) {
        for (const key in userData.months) {
          if (key != "total") {
            userData.months[key] = userData.months["total"];
            this.calculateUserMonth(measure, userData, key);
          }
        }
        this.getMonthTotal(measure.months, measure.showPercentage || measure.isAverageCalculation);
      } else {
        for (const key in userData.months) {
          if (key != "total") {
            userData.months[key] =
              Math.round((userData.months["total"] / 12) * 100) / 100;
            this.calculateUserMonth(measure, userData, key);
          }
        }
        this.getMonthTotal(measure.months, measure.showPercentage || measure.isAverageCalculation);
      }
    },
    handleScroll () {
      var headersList = ["sales-plan-months-header-company", "sales-plan-months-header-tactical" , "sales-plan-months-header-activities"];

      for(var i = 0; i < headersList.length; i ++)
      {
        var salesPlanHeader = document.getElementById(headersList[i]);
      
        if(salesPlanHeader != null && salesPlanHeader != undefined){
          var doc = document.documentElement;
          var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

          if(top >= 150){
              salesPlanHeader.classList.add("fixed-sales-plan-header")
              salesPlanHeader.style.width = salesPlanHeader.parentNode.getBoundingClientRect().width + "px";
          }else{
              salesPlanHeader.classList.remove("fixed-sales-plan-header");
              salesPlanHeader.style.width = "auto";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.data-year {
  width: 70px;
}
.data-year input {
  text-align: center;
}
.v-expansion-panels.v-expansion-panels--accordion.v-expansion-panels--flat {
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.ex-icon {
  order: 0;
}
.ex-header {
  order: 1;
}
.measure-months-header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
  padding-bottom: 25px;
}
.measure-months {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
}
.user-months {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 40px;
}
.user-m {
  min-width: 80px;
  padding: 0px 5px 0px 5px;
}
.user-m div {
  display: inline-flex;
}
.user-m input {
  text-align: right;
}
.ex-header.ex-year-header {
  padding-left: 24px;
}
.ex-header.ex-year-header div {
  color: #6f6f6f;
}

.user-m.measure-name {
  padding-top: 10px;
  word-break: break-word;
  min-width: 140px;
}
.user-m.user-m-name {
  padding-left: 15px;
  word-break: break-word;
  min-width: 140px;
  font-size:0.85rem;
  position:relative;
  top:8px;
}
.measure-months .user-m.measure-name {
  text-align: left;
}
.measureMonths .v-expansion-panel-header__icon {
  margin-top: -18px !important;
}
.user-months .user-m.measure-name {
  text-align: left;
}
.v-expansion-panel-content.ex-cdata
  .v-expansion-panel-content__wrap:first-child {
  padding: 0;
}
.v-expansion-panel-content.inner-panel .v-expansion-panel-content__wrap {
  padding: 0 24px 16px !important;
}
.v-expansion-panel-content.ex-cdata {
  overflow-x: scroll;
}
.pt-c-10 {
  padding-top: 10px !important;
}
.measureMonths .v-expansion-panel--disabled .v-expansion-panel-header__icon {
  display: block !important;
  opacity: 0;
}
.measureMonths .v-expansion-panel--disabled .v-expansion-panel-header {
  pointer-events: all;
}
.v-expansion-panels.v-expansion-panels--accordion.v-expansion-panels--flat {
  border-bottom: none;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  border-bottom: 1px solid #ccc;
}
.theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-readonly.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
.v-input--is-readonly.v-text-field > .v-input__control > .v-input__slot:after {
  border: none;
}
.valueText .user-m.measure-name {
  padding-top: 0px;
}
.valueText .measureMonths .v-expansion-panel-header__icon {
  margin-top: -4px !important;
}
//center
.valueText .user-months .user-m div {
  display: flex;
  justify-content: center;
}
.valueText .user-months .user-m.user-m-name {
  display: inline-flex !important;
}
.valueText .months-txt {
  display: flex !important;
  justify-content: center !important;
}
.valueText .value-text {
  display: flex !important;
  justify-content: center !important;
}
.fixed-sales-plan-header{
  position:fixed;
  z-index:9999999 !important;
  top:64px;
}
.fixed-sales-plan-header .user-m{
  position:relative;
  top:18px;
}
</style>
